import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react';
import { getColor, mq } from '@stylesheets/settings';

const StyledMixedCopy = styled.div`
  margin: 0 auto;

  p {
    padding: 10px 0;
  }

  h1 {
    background-color: ${getColor('yellow')};
    padding: 0 20px;
    display: inline-block;
    font-size: 20px;

    ${mq('m')} {
      font-size: 42px;
    }
  }

  h2,
  h3 {
    text-transform: uppercase;
    color: black;
    font-weight: normal;
  }

  h2 {
    margin: 1rem 0;

    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  a {
    border-bottom: 1px solid black;
    color: black;
    text-decoration: none;
  }
`;

const MixedCopy = ({ html, className, ...props }) => (
  <StyledMixedCopy dangerouslySetInnerHTML={{ __html: html }} className={className} {...props} />
);

MixedCopy.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string
};

MixedCopy.defaultProps = {
  className: '',
  html: null
};

export default MixedCopy;
