import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby'
import { getGridSpans } from '@stylesheets/settings'

import Layout from "../components/layout"
import { IconClose } from '../components/elements/icon'
import MixedCopy from '../components/elements/mixedcopy'
import SEO from "../components/seo"
import TermsContent from '../static/data/terms.html'

const Wrapper = styled.div`
  display: flex;
  ${getGridSpans('width', { s:4, m: [4, 'full'], l: 6 })};
  margin: 0 auto;
  padding: 50px 0;
`;

const CloseButton = styled(Link)`
  position: absolute; 
  right: 20px; 
  top: 20px;
  width: 40px;
  height: 40px;
  alignSelf:flex-end;
`;

const TermsPage = () => (
  <Layout>
    <SEO title="Terms" />
    <CloseButton to="/"><IconClose fill={'black'} width={'25px'} height={'25px'} /></CloseButton>
    <Wrapper>
      <MixedCopy html={TermsContent}></MixedCopy>
    </Wrapper>
  </Layout>
)

export default TermsPage
